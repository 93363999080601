<template>
  <div>
    <p class="mb-3">
      If the rate of consumption of reagent A,
      <stemble-latex content="$-\frac{\text{d[A]}}{\text{dt}}\text{,}$" />
      is initially measured at
      <number-value :value="rateA" unit="\text{M/s,}" />
      determine the rate of formation of reagent C,
      <stemble-latex content="$\frac{\text{d[C]}}{\text{dt}}\text{,}$" />
      based on the following balanced equation:
    </p>

    <p class="pl-10 mb-4">
      <chemical-latex :content="`3A(g) + 2B(g) -> ${coefC}C(g)`" />
    </p>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block pt-5"
          :value="option.value"
        >
          <template #label>
            <latex-number number="\dfrac{\text{d[C]}}{\text{dt}}=\ " />
            <latex-number :number="option.expression" unit="\text{M/s}" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';
import LatexNumber from '../displayers/LatexNumber';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question318',
  components: {
    ChemicalLatex,
    StembleLatex,
    NumberValue,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    coefC() {
      // return this.taskState.getValueBySymbol('coefC').content.value;
      return 5;
    },
    rateA() {
      return this.taskState.getValueBySymbol('rateA').content;
    },
    rateAFloat() {
      return this.rateA.toFloat();
    },
    rateC() {
      return (this.rateAFloat / 3) * this.coefC;
    },
    rateCwrongStoich() {
      return (3 / this.coefC) * this.rateAFloat;
    },
    rateCneg() {
      return -this.rateC;
    },
    rateCwrongStoichNeg() {
      return -this.rateCwrongStoich;
    },
    options1() {
      return [
        {expression: this.rateCneg.toFixed(2), value: 'rateCneg'},
        {
          expression: this.rateCwrongStoich.toFixed(2),
          value: 'rateCwrongStoich',
        },
        {expression: this.rateC.toFixed(2), value: 'rateC'},
        {
          expression: this.rateCwrongStoichNeg.toFixed(2),
          value: 'rateCwrongStoichNeg',
        },
        {expression: this.rateAFloat.toFixed(2), value: 'rateA'},
      ];
    },
  },
};
</script>
